body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.note-player {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
}

.note-emoji {
  font-size: 48px;
  text-align: center;
  margin-bottom: 16px;
}

.note-player h1 {
  color: #333;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}

.note-player p {
  margin-bottom: 16px;
  line-height: 1.6;
}

.audio-player {
  padding: 10px;
}

.audio-player audio {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
}

.summary {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 20px;
  margin-top: 20px;
  color: #888888;
  text-align: center;
  font-size: 20px;
}

.footer {
  text-align: center;
  padding: 20px;
  margin-top: 30px;
  font-size: 14px;
  color: #333;
}

.footer img {
  vertical-align: middle;
  margin-right: 5px;
}

.footer a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.footer a:hover {
  text-decoration: underline;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-container button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4285F4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #357ae8;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
}

.user-info {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  font-size: 14px;
  color: #666;
}

.app-main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.user-profile {
  max-width: 600px;
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.user-profile h1 {
  color: #333;
  margin-bottom: 20px;
}

.user-profile p {
  margin-bottom: 10px;
}

.user-profile strong {
  font-weight: bold;
  color: #555;
}

/* Estilos responsivos */
@media (max-width: 600px) {
  .note-player {
    padding: 16px;
  }

  .note-emoji {
    font-size: 48px;
    padding: 16px;
  }

  .note-player h1 {
    font-size: 35px;
    padding: 10px;
  }

  .audio-player {
    padding: 10px;
  }

}